//jshint esversion:6
const env = require("../../env");

document.querySelector(".HBD").textContent = env.hbdMsg;
document.querySelector(".text").textContent = env.showScrollMsg;
document.querySelector(".name").textContent = env.name;
document.querySelector(".nickname").textContent = env.nickname;
const animation = document.querySelectorAll("#animation");

const button = document.querySelector(".btn"),
  darkroom = document.querySelector(".darkroom");

// These are the text elements that hold messages to be displayed in the respective screes

const blackText = document.querySelectorAll(".bb-text"), // msgs in the dark room scene
  CTAtext = document.querySelector(".btn-ref");

//Elements in the card page

const frames = document.querySelectorAll(".frame");

//Sfx files

const light = document.querySelector(".switch-aud"),
  music = document.querySelector(".hbd-aud");

//  readMsg() displays the paras in each scene successively. It takes an array of the para elements as input.

const readMsg = (text) => {
  for (let i = 0; i < text.length; i++) {
    // this loop goes through all the text msg paras
    setTimeout(() => {
      // A timeout of 5s ia applied to all text elements so that appear successively one after the other
      text[i].classList.add("read"); // this adds a fadeIn-fadeOut animation to elements
      if (i === text.length - 1) {
        // this ensures that the button appears only after the last text is displayed.
        button.style.display = "inline-block";
        CTAtext.style.display = "block";
      }
    }, 5000 * i);
  }
};

// transition() is animation for change from one scene to another. It takes the current scene div element as input.

const transition = (currentScene) => {
  currentScene.classList.add("fade-in");
  currentScene.style.opacity = "0";
  button.style.display = "none";
  CTAtext.style.display = "none";
};

//Animation Code

/*
    In the beginning, the black page appears signifying a dark room and after displaying the msg paras
    one by one, a button(bulb) appears and the user is asked to click the button to swith on the lights.
*/

export const animate = function () {
  CTAtext.innerHTML = "Click the Light Bulb.";
  console.log(frames[0]);

  readMsg(blackText);
  button.addEventListener("click", function () {
    setTimeout(() => {
      light.play();

      transition(darkroom);
      music.loop = true;
      music.play();

      setTimeout(() => {
        frames[1].style.display = "none";
        frames[0].style.display = "flex";
        frames[0].classList.add("appear");
        frames[0].style.opacity = "1";
        animation.forEach((element) => {
          element.style.opacity = 1;
          element.classList.add("appear");
          setTimeout(() => element.classList.remove("appear"), 1500);
        });
      }, 1000);
    }, 1000);
  });
};
