const env = {
  name: "Saba",

  nickname: "Fittonia 💖",

  pic: "./src/pic.webp",

  hbdMsg: "Wishing you a day filled with happiness and a year filled with joy. Happy birthday!",

  showScrollMsg: "Sending you smiles for every moment of your special day…Have a wonderful time and a very happy birthday!",

  birthDate: "2024-01-24",
};

module.exports = env;
